export function loadExternalScript(script) {
	var referenceScript = document.createElement('script');
	referenceScript.type = 'text/javascript';
	referenceScript.src = script;
    return referenceScript;
}
export function loadStyleCSS() {
	const stylesheet = document.createElement("div");
	if(stylesheet){
		stylesheet.innerHTML = `<link href="https://staticfileszyxme.s3.us-east.cloud-object-storage.appdomain.cloud/anonymous/static/style.css" rel="stylesheet"><link href="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.6/css/intlTelInput.min.css" rel="stylesheet">`;
		document.querySelector('head').append(stylesheet);
	}		
}
export function handleNumericInput(numberFormList) {
	if (numberFormList.length > 0) {
		numberFormList.forEach(element => {
			intlTelInput(document.querySelector(`#${element}`), {
				autoPlaceholder: 'aggressive',
				initialCountry: 'PE',
				separateDialCode: true,
				utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.6/js/utils.min.js'
			});
		});
        return numberFormList;
	}
}
export function generateId(length) {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(length));
}

let extraJavaScript = "";
let extraHeaderCss = "";
let extraBodyCss = "";

export function handleExtraCode() {
	try {
		var extraStyle = document.createElement("style");

		if (extraJavaScript !== "") {
			var extraScript = document.createElement('script');

			extraScript.type = 'text/javascript';
			extraScript.innerText = extraJavaScript;

			document.getElementsByTagName('head')[0].appendChild(extraScript);
		}

		if (extraHeaderCss !== "") {
			extraStyle.innerHTML = extraStyle.innerHTML + extraHeaderCss;
		}

		if (extraBodyCss !== "") {
			extraStyle.innerHTML = extraStyle.innerHTML + extraBodyCss;
		}

		document.querySelector('head').append(extraStyle);
	}
	catch (error) {
		console.error(error);
	}
}

export function getTextColor(hexColor) {
	if (hexColor.length === 4) {
		hexColor = `${hexColor}${hexColor.substr(1, 3)}`;
	}

	var greenColor = parseInt(hexColor.substr(3, 2), 16);
	var blueColor = parseInt(hexColor.substr(5, 2), 16);
	var redColor = parseInt(hexColor.substr(1, 2), 16);

	var brightness = ((redColor * 299) + (greenColor * 587) + (blueColor * 114)) / 1000;

	if (brightness >= 128) {
		return '#111111';
	}
	else {
		return '#FFFFFF';
	}
}