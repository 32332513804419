const environment = "TESTING";

export let BRIDGE_URL;
export let HOOK_URL;

switch (environment) {
    case "PRODUCTION":
        BRIDGE_URL = "https://cloudprdbridge.laraigo.com/api/";
        HOOK_URL = "https://cloudprdhook.laraigo.com/api/";
        break;
    case "TESTING":
        BRIDGE_URL = "https://zyxmelinux2.zyxmeapp.com/zyxmetest/bridge/api/";
        HOOK_URL = "https://zyxmelinux2.zyxmeapp.com/zyxmetest/hook/api/";
        break;
    case "DEV":
        BRIDGE_URL = "https://zyxmelinux2.zyxmeapp.com/zyxme/hook/api/";
        HOOK_URL = "https://zyxmelinux2.zyxmeapp.com/zyxme/hook/api/";
        break;
}
