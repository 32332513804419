export const myFunction = () => {
    console.log("Hola, soy una función exportada");
  };
export async function fetchData() {
  const response = await fetch('https://rickandmortyapi.com/api/character');
  const data = await response.json();
  console.log(data)
  return data;
}

export function loadRecaptcha(intervalId) {
	const container = document.getElementById("chat-history-chatweb");

	// Comprueba si el contenedor existe
	if (container) {
		// Detiene el intervalo de tiempo
		clearInterval(intervalId);

		// Pinta el reCAPTCHA en el contenedor
		container.innerHTML += `
		<div class="g-recaptcha" data-sitekey="6LdrHigkAAAAAFW9Z6Hb0pJL-PfDlQNa8EspLFFt" id="recaptcha"></div>
	  `;
		grecaptcha.render('recaptcha', {});
	}
}