export function httpGetRequestFetch(endpoint, async) {
	return new Promise((resolve, reject) => {
		try {
			fetch(endpoint, {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
			})
				.then(response => {
					if (!response.ok) {
						console.log('GET ERROR');
						reject('ERROR');
					}
					console.log('GET SUCCESS');
					return response.json();
				})
				.then(data => {
					resolve(data);
				});
		} catch (error) {
			console.error(error);
			reject('ERROR');
		}
	});
}
export function fetchRequest(endpoint, body, method = "POST") {
	return new Promise((res, rej) => {
		fetch(endpoint, {
			method,
			body: body ? JSON.stringify(body) : undefined,
			headers: {
				'Content-Type': 'application/json',
			}
		})
			.then(response => {
				if (response.ok) {
					response.text().then(text => {
						res(text)
					})
				} else {
					res("ERROR")
				}
			});
	});
}