import { BRIDGE_URL, HOOK_URL } from './config/environment';
import { loadRecaptcha } from "./helpers/recaptcha"
import { loadExternalScript, loadStyleCSS, handleNumericInput, generateId, handleExtraCode, getTextColor } from "./helpers/helpers"
import { getDataLeads, getElementForm,getStyleForm, getURLthanks, buildForm, getIntegrationData } from "./helpers/integrationFormWeb"


let integrationId = document.currentScript.getAttribute('integrationid');
let containerid = document.currentScript.getAttribute('containerid');

let titleform = '';
let footerform = ''
let colotBackgroundButton = '';
let colorBackgroundForm = ''
let stylesCSSButton = '';
let stylesCSSInput = '';
let colorLabel = '';
let textButtonSend = '';
let showRecaptcha = false;
let urlThanks = '';

let showForm = true;

let formHtmlData = "";
var numberFormList = [];
var formDataList = [];

let laraigo_corpid = 326;
let laraigo_orgid = 399;
let description = '';

let userId = null;

let intervalId;

const initializeFormWeb = async () => {
    loadStyleCSS();

    var head = document.getElementsByTagName('head')[0];
    //load script externos
    const intlTelInput = loadExternalScript('https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.6/js/intlTelInput.min.js');
    head.appendChild(intlTelInput);
    const retcapcha = loadExternalScript('https://www.google.com/recaptcha/api.js');
    head.appendChild(retcapcha);

    await printForm();
    if (showRecaptcha) {
        loadRecaptcha(intervalId);
    }
    handleNumericInput(numberFormList);
}
document.addEventListener('DOMContentLoaded', initializeFormWeb);

async function printForm() {
    try {

        const integrationData = await getIntegrationData(integrationId);

        var form = await buildForm(integrationData,formDataList, formHtmlData, numberFormList);
        formDataList = form.formDataList
        formHtmlData = form.formHtmlData 
        numberFormList = form.numberFormList
        /*       
        if (!form) {
            console.error("Error while building form");
            throw new Error(("Error while building form"));
        }
        */

        //capture data for module leads
        const leads = await getDataLeads(integrationData);
        description = leads.description;
        laraigo_corpid = leads.laraigo_corpid;
        laraigo_orgid = leads.laraigo_orgid

        //capture element form
        const elementForm = await getElementForm(integrationData)
        titleform = elementForm.titleform
        footerform = elementForm.footerform,
        textButtonSend = elementForm.textButtonSend,
        showRecaptcha = elementForm.showRecaptcha

        //load style css of user
        const style = await getStyleForm(integrationData)

        //get urlthanks
        urlThanks = await getURLthanks(integrationData)

    } catch (error) {
        throw new Error(("Error while building form"));
    }

    if (localStorage.getItem(`userId${integrationId}`) !== 'undefined' && localStorage.getItem(`userId${integrationId}`)) {
        userId = localStorage.getItem(`userId${integrationId}`);
    }

    const formStructure = document.createElement("div");
    formStructure.innerHTML = `

						<form class="form-container" id="formWebVCA">
						<div class="title">
							<span>${titleform || "Completa tus datos"}</span>
						</div>
							<div class="" id="chat-history-chatweb" >${formHtmlData}<div class="form-web-alert" id="chat-history-alert-box"></div></div>
                            <p class="footer-p">${footerform}</p>
							<button type="button" class="ButtonSend" id="sendFormButton" onclick="laraigoLeadSend()" title="Enviar formulario">
							 ${textButtonSend}
							</button>
						</form>
    `
    document.getElementById(containerid).append(formStructure);
    handleExtraCode();
}

window.validateKeyPress = function (event, regex) {
    if (typeof regex !== 'undefined' && regex !== 'undefined' && regex) {
        var eventData = event || window.event;
        var keyInput;

        var regexPattern = new RegExp(regex);

        if (eventData.type === 'paste') {
            keyInput = event.clipboardData.getData('text/plain');
        }
        else {
            var keyData = eventData.keyCode || eventData.which;
            keyInput = String.fromCharCode(keyData);
        }

        if (!regexPattern.test(keyInput)) {
            eventData.returnValue = false;

            if (eventData.preventDefault) {
                eventData.preventDefault();
            }
        }
    }
}
window.validateChange = function (event, regex, id) {
    if (typeof regex !== 'undefined' && regex !== 'undefined' && regex) {
        if (typeof id !== 'undefined' && id !== 'undefined' && id) {
            var eventData = event || window.event;
            var keyInput = eventData.currentTarget.value;

            var regexPattern = new RegExp(regex);

            if (!regexPattern.test(keyInput)) {
                document.getElementById(`alert-${id}`).style.display = "block";
            }
            else {
                document.getElementById(`alert-${id}`).style.display = "none";
            }
        }
    }
}
window.laraigoLeadSend = function () {
    if (showForm) {
        var formSuccess = true;
        var lastElement = "";
        var formData = [];

        if (formDataList.length > 0) {
            formDataList.forEach(element => {
                var elementValue = document.getElementById(`form-${element.field}`).value;

                if (element.type !== 'phone') {

                    if (element.required !== 'undefined') {

                        if (element.required) {

                            if (elementValue === 'undefined' || !elementValue) {
                                document.getElementById(`alert-${element.field}`).style.display = "block";
                                lastElement = element.label;
                                formSuccess = false;
                            }
                            else {
                                document.getElementById(`alert-${element.field}`).style.display = "none";
                            }
                        }
                    }

                    if (element.inputvalidation !== 'undefined' && element.inputvalidation) {
                        var regexPattern = new RegExp(element.inputvalidation);

                        if (!regexPattern.test(elementValue)) {
                            document.getElementById(`alert-${element.field}`).style.display = "block";
                            lastElement = element.label;
                            formSuccess = false;
                        }
                        else {
                            document.getElementById(`alert-${element.field}`).style.display = "none";
                        }
                    }
                }
                else {
                    if (element.required !== 'undefined') {
                        if (element.required) {
                            if (elementValue === 'undefined' || !elementValue) {
                                document.getElementById(`alert-${element.field}`).style.display = "block";
                                lastElement = element.label;
                                formSuccess = false;
                            }
                            else {
                                document.getElementById(`alert-${element.field}`).style.display = "none";
                            }
                        }
                    }

                    if (elementValue !== 'undefined' && elementValue) {
                        if (!window.intlTelInputGlobals.getInstance(document.getElementById(`form-${element.field}`)).isValidNumber()) {
                            document.getElementById(`alert-${element.field}`).style.display = "block";
                            lastElement = element.label;
                            formSuccess = false;
                        }
                        else {
                            document.getElementById(`alert-${element.field}`).style.display = "none";
                        }
                    }
                }


                if (formSuccess) {
                    if (element.type !== 'phone') {
                        formData.push({
                            field: element.field,
                            label: element.label,
                            value: elementValue
                        });
                    }
                    else {
                        formData.push({
                            value: window.intlTelInputGlobals.getInstance(document.getElementById(`form-${element.field}`)).getNumber().split('+')[1],
                            field: element.field,
                            label: element.label
                        });
                    }
                }
            });
        }
        userId = generateId(16);
        localStorage.setItem(`userId${integrationId}`, userId);

        let dataLeads = {};
        formData.forEach(function (inputForm) {
            if (inputForm.field === 'FIRSTNAME') {
                dataLeads.name = inputForm.value;
            } else if (inputForm.field === 'EMAIL') {
                dataLeads.email = inputForm.value;
            } else if (inputForm.field === 'LASTNAME') {
                dataLeads.lastname = inputForm.value;
            } else if (inputForm.field === 'PHONE') {
                dataLeads.phone = inputForm.value;
            }
        });

        dataLeads.corpid = laraigo_corpid;
        dataLeads.orgid = laraigo_orgid;
        dataLeads.web = description;
        dataLeads.origin = window.location.href;

        // valida si el formulario es correcto
        if (!formSuccess) {
            document.getElementById('chat-history-alert-box').innerHTML = `El campo <strong>${lastElement}</strong> no cumple con nuestras reglas de validacion!`;
            return;
        }

        return new Promise((resolve, reject) => {
            try {
                console.log("fetch")
                console.log(dataLeads)
                console.log(HOOK_URL)
                fetch(`${HOOK_URL}communication/lead`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(dataLeads),
                })
                    .then(response => {
                        console.log(JSON.stringify(response.status))

                        if (response.status === 200) {
                            window.location.href = urlThanks;
                            resolve();
                        } else {
                            console.log("error")
                            reject(null);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        reject(error);
                    });
            } catch (error) {
                console.log(error);
                reject(error);
            }
            document.getElementById("sendFormButton").remove();
            document.getElementById("chat-history-chatweb").innerHTML = `</div ><h3 style="text-align: center;">Gracias por contactarte con nosotros, en breve nos comunicaremos.</h3></div>`

            localStorage.setItem(`showForm${integrationId}`, "false");
            showForm = false;
        });

    }

};