import { httpGetRequestFetch } from "../services/fetchRequest"
import { baseBrokerEndpoint } from "../config/constanst";


export async function getIntegrationData(integrationId) {
    if (!integrationId) {
        console.error("does no existe integrationId")
        return new error("error fetch to get all integrationID")
    }
    var integrationData = await httpGetRequestFetch(`${baseBrokerEndpoint}integrations/${integrationId}`, false)
    return integrationData
}


export async function getDataLeads(integrationData) {
    try {
        var description, laraigo_corpid, laraigo_orgid
        if (integrationData.status === 'ACTIVO') {
            //name is description of dataLeads
            if (typeof integrationData.name !== 'undefined' && integrationData.name !== null) {
                description = integrationData.name;
            }
            //org and corp
            if (typeof integrationData.metadata.extra.corpid !== 'undefined' && integrationData.metadata.extra.corpid !== null) {
                laraigo_corpid = integrationData.metadata.extra.corpid;
            }
            if (typeof integrationData.metadata.extra.orgid !== 'undefined' && integrationData.metadata.extra.orgid !== null) {
                laraigo_orgid = integrationData.metadata.extra.orgid;
            }
        } else {
            console.error(error)
            return new error("integrationData doesnt exist")
        }
        const leads = {
            description: description,
            laraigo_corpid: laraigo_corpid,
            laraigo_orgid: laraigo_orgid
        }
        return leads;
    } catch (error) {
        console.error(error)
        return new error("error getDataLeads")
    }
}

export async function getElementForm(integrationData) {
    try {
        var titleform, footerform, textButtonSend, showRecaptcha;
        if (integrationData.status === 'ACTIVO') {
            //get element form

            if (typeof integrationData.metadata.extra !== 'undefined' && integrationData.metadata.extra) {
                // element title
                if (typeof integrationData.metadata.extra.titleform !== 'undefined' && integrationData.metadata.extra.titleform !== null) {
                    titleform = integrationData.metadata.extra.titleform;
                }
                //element title footer
                if (typeof integrationData.metadata.extra.footerform !== 'undefined' && integrationData.metadata.extra.footerform !== null) {
                    footerform = integrationData.metadata.extra.footerform;
                }
                //element text button
                if (typeof integrationData.metadata.extra.textButtonSend !== 'undefined' && integrationData.metadata.extra.textButtonSend !== null) {
                    textButtonSend = integrationData.metadata.extra.textButtonSend;
                }
                //element value recaptcha
                if (typeof integrationData.metadata.extra.recaptcha !== 'undefined' && integrationData.metadata.extra.recaptcha !== null) {
                    showRecaptcha = integrationData.metadata.extra.recaptcha;
                }
            }
        }
        const elementForm = {
            titleform,
            footerform,
            textButtonSend,
            showRecaptcha
        }
        return elementForm;
    } catch (error) {
        console.error('error fetch to get all integrationID')
        return new error("error fetch to get all integrationID")
    }
}

export async function getStyleForm(integrationData) {
    try {
        if (integrationData.status === 'ACTIVO') {

            //get element form
            if (typeof integrationData.metadata.extra !== 'undefined' && integrationData.metadata.extra) {

                //style background Form
                if (typeof integrationData.metadata.extra.colorBackgroundForm !== 'undefined' && integrationData.metadata.extra.colorBackgroundForm) {
                    var backgroundForm = document.createElement("style");
                    if (customStyle) {
                        customStyle.id = "customStyle";
                        customStyle.innerHTML = customStyle.innerHTML + `.form-container {background-color: ${integrationData.metadata.extra.colorBackgroundForm};`;
                        document.querySelector('head').append(backgroundForm);
                    }
                }
                //color title
                if (typeof integrationData.metadata.extra.colorLabel !== 'undefined' && integrationData.metadata.extra.colorLabel) {
                    var customStyle = document.createElement("style");
                    if (customStyle) {
                        customStyle.id = "customStyle";
                        customStyle.innerHTML = customStyle.innerHTML + `.title {color: ${integrationData.metadata.extra.colorLabel};`;
                        document.querySelector('head').append(customStyle);
                    }
                }

                //style background button
                if (typeof integrationData.metadata.extra.colotBackgroundButton !== 'undefined' && integrationData.metadata.extra.colotBackgroundButton) {
                    var customStyle = document.createElement("style");
                    if (customStyle) {
                        customStyle.id = "customStyle";
                        customStyle.innerHTML = `.ButtonSend {background-color: ${integrationData.metadata.extra.colotBackgroundButton};`;
                        document.querySelector('head').append(customStyle);
                    }
                }

                //style Css Button
                if (typeof integrationData.metadata.extra.stylesCSSButton !== 'undefined' && integrationData.metadata.extra.stylesCSSButton !== null) {
                    var customStyle = document.createElement("style");
                    if (customStyle) {
                        customStyle.id = "customStyle";
                        customStyle.innerHTML = customStyle.innerHTML + `.ButtonSend {${integrationData.metadata.extra.stylesCSSButton};`;
                        document.querySelector('head').append(customStyle);
                    }
                }
                // style input
                if (typeof integrationData.metadata.extra.stylesCSSInput !== 'undefined' && integrationData.metadata.extra.stylesCSSInput !== null) {
                    var customStyle = document.createElement("style");
                    if (customStyle) {
                        customStyle.id = "customStyle";
                        customStyle.innerHTML = customStyle.innerHTML + `.chat-form-input {${integrationData.metadata.extra.stylesCSSInput};`;
                        document.querySelector('head').append(customStyle);
                    }
                }

            }
        }
        return true;
    } catch (error) {
        console.error(error)
        return new error("error fetch to get all integrationID")
    }
}


export async function getURLthanks(integrationData) {
    try {
        var urlthanks = '';
        if (integrationData.status === 'ACTIVO') {
            if (typeof integrationData.metadata.extra !== 'undefined' && integrationData.metadata.extra) {
                //url thanks urlThanks
                if (typeof integrationData.metadata.extra.urlThanks !== 'undefined' && integrationData.metadata.extra.urlThanks !== null) {
                    urlthanks = integrationData.metadata.extra.urlThanks;
                }
            }
        }
        const element = {
            urlthanks,
        }
        return urlthanks;
    } catch (error) {
        console.error('error fetch to get all integrationID')
        return new error("error fetch to get all integrationID")
    }
}

export async function buildForm(integrationData, formDataList, formHtmlData, numberFormList) {
    try {

        if (integrationData.status === 'ACTIVO') {
            if (typeof integrationData.metadata !== 'undefined' && integrationData.metadata) {

                if (typeof integrationData.metadata.form !== 'undefined' && integrationData.metadata.form) {
                    if (integrationData.metadata.form.length > 0) {
                        formDataList = integrationData.metadata.form;
                        formDataList.forEach(value => {
                            if (value.type !== 'phone') {
                                formHtmlData += `<div class="chat-form-element"><label class="chat-form-label">${value.label}</label><input class="chat-form-input" type="${value.type}" onkeypress='validateKeyPress(event, "${value.keyvalidation}")' onchange='validateChange(event, "${value.inputvalidation}", "${value.field}")' placeholder="${value.placeholder}" id="form-${value.field}" required></div><span class="chat-form-alert" id="alert-${value.field}">${value.validationtext}</span>`;
                            }
                            else {
                                formHtmlData += `<div class="chat-form-element"><label class="chat-form-label">${value.label}</label><input class="chat-form-input" type="${value.type}" onkeypress='validateKeyPress(event, "${value.keyvalidation}")' onchange='validateChange(event, "${value.inputvalidation}", "${value.field}")' placeholder="${value.placeholder}" id="form-${value.field}"></div><span class="chat-form-alert" id="alert-${value.field}">${value.validationtext}</span>`;
                                numberFormList.push(`form-${value.field}`)
                            }
                        });
                    } else {
                        console.log("NO FORM");

                        localStorage.setItem(`showForm${integrationId}`, "false");
                        if (localStorage.getItem(`userId${integrationId}`) === 'undefined' || !localStorage.getItem(`userId${integrationId}`)) {
                            localStorage.setItem(`userId${integrationId}`, generateId(16));
                        }
                    }
                }
                else {
                    console.log("NO FORM");
                    localStorage.setItem(`showForm${integrationId}`, "false");
                    if (localStorage.getItem(`userId${integrationId}`) === 'undefined' || !localStorage.getItem(`userId${integrationId}`)) {
                        localStorage.setItem(`userId${integrationId}`, generateId(16));
                    }
                }
            }
        }

        const form = {
            formDataList,
            formHtmlData,
            numberFormList
        }
        return form
    } catch (error) {
        console.error(error)
    }
}